<template>
  <div>
    <div v-if="notes[0]">
      <span class="flix-bg-warning" >
        <i v-html="$nl2br(notes[0].msg)" />
      </span>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: Object
  },
  data () {
    return {
      notes: []
    }
  },
  methods: {
    getLastNote () {
      this.$flix_post({
        url: 'notes/get_specific',
        data: {
          user: this.$route.params.user,
          ID: this.data.ID,
          category: ['status_note']
        },
        callback: function (ret) { if (!ret.data[0] || !ret.data[1].length) { return false } this.notes = ret.data[1] }.bind(this)
      })
    }
  },
  mounted () {
    this.getLastNote()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  span
    margin-top: 10px
    display: inline-block
    padding: 12px 7px
</style>
